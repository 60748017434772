<template>
  <div id="attach-to">
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-time-picker
              @getDateFrom="dateFrom = $event"
              @getDateTo="dateTo = $event"
            >
            </date-time-picker>
            <v-btn
              class="mt-5"
              color="primary"
              @click="getData"
            >
              {{ $t('BtnSearch') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="w-full">
          <v-overlay
            :absolute="true"
            :value="loading"
            opacity="0.5"
            color="#c0c0c0"
          >
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card-title>
            {{ $t('EventGroups') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-btn
                  outlined
                  color="primary"
                  @click="exportToExcel()"
                >
                  {{ $t('VtrReportElements.Download') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center text-uppercase">
                          {{ $t('VTR.SoundOff') }}
                        </th>
                        <th class="text-center text-uppercase">
                          {{ $t('VTR.SoundOn') }}
                        </th>
                        <th class="text-center text-uppercase">
                          {{ $t('VTR.PausePressed') }}
                        </th>
                        <th class="text-center text-uppercase">
                          {{ $t('VTR.ClousedByUser') }}
                        </th>
                        <th class="text-center text-uppercase">
                          {{ $t('VTR.SkipAdv') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          <h3>
                            {{ dataFromDb.volumeOff }}
                          </h3>
                        </td>
                        <td class="text-center">
                          <h3>
                            {{ dataFromDb.volumeOn }}
                          </h3>
                        </td>
                        <td class="text-center">
                          <h3>
                            {{ dataFromDb.clickedPause }}
                          </h3>
                        </td>
                        <td class="text-center">
                          <h3>
                            {{ dataFromDb.clickedClose }}
                          </h3>
                        </td>
                        <td class="text-center">
                          <h3>
                            {{ dataFromDb.clickedSkip }}
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="w-full">
          <v-overlay
            :absolute="true"
            :value="loading"
            opacity="0.5"
            color="#c0c0c0"
          >
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card-title>
            {{ $t('ViewDurationGroups') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <progress-component
                  :raw-value="views.view_25.value"
                  :percent-value="views.view_25.percent"
                  :title="$t('PrecentUpTo25')"
                >
                </progress-component>
                <progress-component
                  :raw-value="views.view_50.value"
                  :percent-value="views.view_50.percent"
                  :title="$t('PrecentUpTo50')"
                >
                </progress-component>
                <progress-component
                  :raw-value="views.view_75.value"
                  :percent-value="views.view_75.percent"
                  :title="$t('PrecentUpTo75')"
                >
                </progress-component>
                <progress-component
                  :raw-value="views.view_100.value"
                  :percent-value="views.view_100.percent"
                  :title="$t('PrecentUpTo100')"
                >
                </progress-component>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
      hide-overlay
    >
      <v-card>
        <v-card-title class="text-h6">
          {{ $t('VTRRequest') }}
        </v-card-title>
        <v-card-text v-if="!vtrRequestSent">
          {{ $t('OpenVTRDescription') }}
        </v-card-text>
        <v-card-text v-else>
          {{ $t('VTRReauestSended') }}
        </v-card-text>
        <v-card-actions v-if="!vtrRequestSent">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="sendVTRRequest()"
          >
            {{ $t('OpenVTR') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiVideoOutline, mdiChartLine } from '@mdi/js'
import qs from 'qs'
import * as XLSX from 'xlsx-js-style'
import DateTimePicker from '../Components/DateTimePicker.vue'
import ProgressComponent from './Components/CampaignCreativeVTRProgressBarComponent.vue'

export default {
  components: {
    DateTimePicker,
    ProgressComponent,
  },
  data() {
    return {
      search: '',
      calories: '',
      isWeekly: false,
      dialog: false,
      vtrRequestSent: true,
      isWeeklyTransfer: false,
      isVideoCampaign: false,
      excelStatistics: [],
      widgetsData: [],
      campaingName: '',
      creativeName: '',
      views: {
        view_25: {
          value: 0,
          percent: 0,
        },
        view_50: {
          value: 0,
          percent: 0,
        },
        view_75: {
          value: 0,
          percent: 0,
        },
        view_100: {
          value: 0,
          percent: 0,
        },
      },
      emptyData: {
        clickedClose: 0,
        clickedPause: 0,
        clickedSkip: 0,
        creativeId: 0,
        viewsTo25: 0,
        viewsTo50: 0,
        viewsTo75: 0,
        viewsTo100: 0,
        volumeOff: 0,
        volumeOn: 0,
      },
      dataFromDb: [],
      daylyData: [],
      statistics: [],
      selectedCampaigns: [],
      creativeId: Number(this.$route.params.creative_id),
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      transferDateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      transferDateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      icons: {
        mdiVideoOutline,
        mdiChartLine,
      },
      totals: {
        currentPeriod: {
          views: 0,
          shows: 0,
          uniqViews: 0,
          clicks: 0,
        },
        totalPeriod: {
          views: 0,
          shows: 0,
          uniqViews: 0,
          clicks: 0,
        },
      },
    }
  },

  watch: {
    selectedCampaigns() {
      // watch it
    },
  },

  mounted() {
    this.getData()

    // this.$root.$on('update_city', this.getData())
  },

  methods: {
    exportToExcel() {
      const wb = XLSX.utils.book_new()
      const rows = []
      const dd = XLSX.utils.json_to_sheet(rows)

      const excelDateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const excelDateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      // Рабочая область
      dd['!ref'] = 'A1:Z50'

      // соединение ячеек
      dd['!merges'] = [
        { s: { r: 0, c: 7 }, e: { r: 0, c: 1 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 1 } },
      ]

      // Ширина столбцов
      dd['!cols'] = [
        { width: 30 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ]

      // верхняя шапка
      dd.A1 = {
        t: 's',
        v: `${this.$t('CampaignStatisticsExcel')}:`,
        s: { font: { bold: true } },
      }
      dd.B1 = {
        t: 's',
        v: this.campaingName,
      }

      dd.A2 = {
        t: 's',
        v: `${this.$t('CreativeStatisticsExcel')}:`,
        s: { font: { bold: true } },
      }
      dd.B2 = {
        t: 's',
        v: this.creativeName,
      }
      dd.A3 = {
        t: 's',
        v: `${this.$t('DateFrom')}:`,
        s: { font: { bold: true } },
      }
      dd.B3 = {
        t: 's',
        v: excelDateStart,
      }
      dd.A4 = {
        t: 's',
        v: `${this.$t('DateTo')}:`,
        s: { font: { bold: true } },
      }
      dd.B4 = {
        t: 's',
        v: excelDateFinish,
      }

      // шапка таблицы
      dd.A6 = {
        t: 's',
        v: this.$t('Date'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd.B6 = {
        t: 's',
        v: this.$t('Shows'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd.C6 = {
        t: 's',
        v: this.$t('References'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd.D6 = {
        t: 's',
        v: this.$t('Views'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd.E6 = {
        t: 's',
        v: this.$t('Сoverage'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }

      // Заполняем таблицу через AoA

      let count = 7
      this.daylyData.forEach(element => {
        // // eslint-disable-next-line no-extra-semi, object-curly-newline
        // ;({ date_string: data[0], show_count: data[1], ref_count: data[2], uniq_count: data[3] } = element)

        // console.log(data)
        // aoaDD.push(data)

        // // console.log(aoaDD)
        dd[`A${count}`] = {
          t: 's',
          v: this.moment(element.statisticsDate).format('YYYY-MM-DD'),
        }
        dd[`B${count}`] = {
          t: 'n',
          v: element.views,
        }
        dd[`C${count}`] = {
          t: 'n',
          v: element.clicks,
        }
        dd[`D${count}`] = {
          t: 'n',
          v: element.shows,
        }
        dd[`E${count}`] = {
          t: 'n',
          v: element.uniqViews,
        }

        // eslint-disable-next-line no-plusplus
        count++
      })

      // XLSX.utils.sheet_add_aoa(dd, aoaDD, { origin: 'A4' })

      // Добавляем итоговые ячейки

      dd[`A${count}`] = {
        t: 's',
        v: this.$t('ReportTotal'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd[`B${count}`] = {
        t: 'n',
        v: this.totals.currentPeriod.views,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd[`C${count}`] = {
        t: 'n',
        v: this.totals.currentPeriod.clicks,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd[`D${count}`] = {
        t: 'n',
        v: this.totals.currentPeriod.shows,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      dd[`E${count}`] = {
        t: 'n',
        v: this.totals.currentPeriod.uniqViews,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, dd, this.$t('DaysDetails'))

      // Лист досмотры
      const wu = XLSX.utils.json_to_sheet(rows)

      // Рабочая область
      wu['!ref'] = 'A1:Z50'

      // соединение ячеек
      wu['!merges'] = [
        { s: { r: 0, c: 7 }, e: { r: 0, c: 1 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 1 } },
      ]

      // Ширина столбцов
      wu['!cols'] = [
        { width: 30 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ]

      // верхняя шапка
      wu.A1 = {
        t: 's',
        v: `${this.$t('CampaignStatisticsExcel')}:`,
        s: { font: { bold: true } },
      }
      wu.B1 = {
        t: 's',
        v: this.campaingName,
      }

      wu.A2 = {
        t: 's',
        v: `${this.$t('CreativeStatisticsExcel')}:`,
        s: { font: { bold: true } },
      }
      wu.B2 = {
        t: 's',
        v: this.creativeName,
      }
      wu.A3 = {
        t: 's',
        v: `${this.$t('DateFrom')}:`,
        s: { font: { bold: true } },
      }
      wu.B3 = {
        t: 's',
        v: excelDateStart,
      }
      wu.A4 = {
        t: 's',
        v: `${this.$t('DateTo')}:`,
        s: { font: { bold: true } },
      }
      wu.B4 = {
        t: 's',
        v: excelDateFinish,
      }

      // шапка таблицы
      wu.A6 = {
        t: 's',
        v: this.$t('ViewShare'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      wu.B6 = {
        t: 's',
        v: this.$t('Shows'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }

      // Добавляем ячейки с показателями

      wu.A7 = {
        t: 's',
        v: this.$t('PrecentUpTo25'),
      }
      wu.A8 = {
        t: 's',
        v: this.$t('PrecentUpTo50'),
      }
      wu.A9 = {
        t: 's',
        v: this.$t('PrecentUpTo75'),
      }
      wu.A10 = {
        t: 's',
        v: this.$t('PrecentUpTo100'),
      }

      wu.B7 = {
        t: 'n',
        v: this.views.view_25.value,
      }
      wu.B8 = {
        t: 'n',
        v: this.views.view_50.value,
      }
      wu.B9 = {
        t: 'n',
        v: this.views.view_75.value,
      }
      wu.B10 = {
        t: 'n',
        v: this.views.view_100.value,
      }

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, wu, this.$t('Watchups'))

      // Лист события
      const ev = XLSX.utils.json_to_sheet(rows)

      // Рабочая область
      ev['!ref'] = 'A1:Z50'

      // соединение ячеек
      ev['!merges'] = [
        { s: { r: 0, c: 7 }, e: { r: 0, c: 1 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 1 } },
      ]

      // Ширина столбцов
      ev['!cols'] = [
        { width: 30 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ]

      // верхняя шапка
      ev.A1 = {
        t: 's',
        v: `${this.$t('CampaignStatisticsExcel')}:`,
        s: { font: { bold: true } },
      }
      ev.B1 = {
        t: 's',
        v: this.campaingName,
      }

      ev.A2 = {
        t: 's',
        v: `${this.$t('CreativeStatisticsExcel')}:`,
        s: { font: { bold: true } },
      }
      ev.B2 = {
        t: 's',
        v: this.creativeName,
      }
      ev.A3 = {
        t: 's',
        v: `${this.$t('DateFrom')}:`,
        s: { font: { bold: true } },
      }
      ev.B3 = {
        t: 's',
        v: excelDateStart,
      }
      ev.A4 = {
        t: 's',
        v: `${this.$t('DateTo')}:`,
        s: { font: { bold: true } },
      }
      ev.B4 = {
        t: 's',
        v: excelDateFinish,
      }

      // шапка таблицы
      ev.A6 = {
        t: 's',
        v: this.$t('ViewShare'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      ev.B6 = {
        t: 's',
        v: this.$t('Shows'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }

      // Заполняем ячейки показателей
      ev.A7 = {
        t: 's',
        v: this.$t('VTR.SoundOff'),
      }
      ev.A8 = {
        t: 's',
        v: this.$t('VTR.SoundOn'),
      }
      ev.A9 = {
        t: 's',
        v: this.$t('VTR.PausePressed'),
      }
      ev.A10 = {
        t: 's',
        v: this.$t('VTR.ClousedByUser'),
      }
      ev.A11 = {
        t: 's',
        v: this.$t('VTR.SkipAdv'),
      }

      ev.B7 = {
        t: 'n',
        v: this.dataFromDb.volumeOff,
      }
      ev.B8 = {
        t: 'n',
        v: this.dataFromDb.volumeOn,
      }
      ev.B9 = {
        t: 'n',
        v: this.dataFromDb.clickedPause,
      }
      ev.B10 = {
        t: 'n',
        v: this.dataFromDb.clickedClose,
      }
      ev.B11 = {
        t: 'n',
        v: this.dataFromDb.clickedSkip,
      }

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, ev, this.$t('Events'))

      console.log(wb)
      const dateString = this.dateFrom != null ? `${this.dateFrom}-${this.dateTo}` : this.$t('AllPeriod')

      // /* Export to file (start a download) */
      XLSX.writeFile(wb, `VTR ${this.campaingName}(${dateString}).xlsx`)
    },

    // sendVTRRequest() {
    //   const emails = ['a.piliptsevich@adspend.ru', 'a.fisenko@adspend.ru']
    //   const data = {
    //     name: 'Письмо с сайта dashboard.mobidriven.com',
    //     email: emails,
    //     title: `Запрос на включение VTR РК ${this.campaign_id}`,
    //     message: `Запрос от пользователя на включение VTR для кампании ${this.campaign_id}`,
    //   }
    //   this.$http.post('/creatives/vtr/request', { data }).then(() => {
    //     localStorage.setItem(`requestSent_${this.dataFromDb.campaign_id}`, true)
    //     this.vtrRequestSent = true
    //   })
    // },
    getData() {
      this.loading = true
      this.transferDateFrom = this.dateFrom
      this.transferDateTo = this.dateTo
      this.isWeeklyTransfer = this.isWeekly
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          creativeId: this.creativeId,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // '/creatives/vtr/data'
      this.$http
        .get(`${this.$apiBaseURL}/creatives/vtr`, params)
        .then(response => {
          // eslint-disable-next-line prefer-destructuring
          this.dataFromDb = response.data !== null ? response.data[0] : this.emptyData
          this.loading = false
        })
        .then(() => {
          const paramsDayly = {
            params: {
              dateFrom: dateStart,
              dateTo: dateFinish,
              userId: this.$getUserId(),
              creativeIds: this.creativeId,

              // advertiserId: this.advertiserId,
            },
            paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
          }
          this.$http
            .get(`${this.$apiBaseURL}/creatives/details`, paramsDayly)
            .then(response => {
              const tempData = response.data !== null ? response.data : []
              this.daylyData = tempData

              if (tempData.length > 0) {
                this.creativeName = tempData[0].creativeName
                this.campaingName = tempData[0].campaign.name
              }

              this.totals.currentPeriod.views = 0
              this.totals.currentPeriod.clicks = 0
              this.totals.currentPeriod.uniqViews = 0
              this.totals.currentPeriod.shows = 0
              if (tempData.length > 0) {
                tempData.forEach(item => {
                  this.totals.currentPeriod.views += item.views
                  this.totals.currentPeriod.clicks += item.clicks
                  this.totals.currentPeriod.uniqViews += item.uniqViews
                  this.totals.currentPeriod.shows += item.shows
                })
              }

              if (this.dataFromDb.viewsTo25 > 0) {
                this.views.view_25.value = this.dataFromDb.viewsTo25
                // eslint-disable-next-line operator-linebreak
                this.views.view_25.percent =
                  Math.round((this.dataFromDb.viewsTo25 / this.totals.currentPeriod.views) * 100 * 100) / 100
              } else {
                this.views.view_25.value = 0
                this.views.view_25.percent = 0
              }
              if (this.dataFromDb.viewsTo50 > 0) {
                this.views.view_50.value = this.dataFromDb.viewsTo50
                // eslint-disable-next-line operator-linebreak
                this.views.view_50.percent =
                  Math.round((this.dataFromDb.viewsTo50 / this.totals.currentPeriod.views) * 100 * 100) / 100
              } else {
                this.views.view_50.value = 0
                this.views.view_50.percent = 0
              }
              if (this.dataFromDb.viewsTo75 > 0) {
                this.views.view_75.value = this.dataFromDb.viewsTo75
                // eslint-disable-next-line operator-linebreak
                this.views.view_75.percent =
                  Math.round((this.dataFromDb.viewsTo75 / this.totals.currentPeriod.views) * 100 * 100) / 100
              } else {
                this.views.view_75.value = 0
                this.views.view_75.percent = 0
              }
              if (this.dataFromDb.viewsTo100 > 0) {
                this.views.view_100.value = this.dataFromDb.viewsTo100
                // eslint-disable-next-line operator-linebreak
                this.views.view_100.percent =
                  Math.round((this.dataFromDb.viewsTo100 / this.totals.currentPeriod.views) * 100 * 100) / 100
              } else {
                this.views.view_100.value = 0
                this.views.view_100.percent = 0
              }

              this.loading = false
            })
            .then(() => {
              const paramsTotal = {
                params: {
                  dateFrom: '1970-01-01',
                  dateTo: `${new Date().toISOString().substr(0, 10)}`,
                  userId: this.$getUserId(),
                  creativeIds: this.creativeId,
                },
              }
              this.$http.get(`${this.$apiBaseURL}/creatives/details`, paramsTotal).then(responseTotal => {
                const tempDataTotal = responseTotal.data !== null ? responseTotal.data : []

                this.totals.totalPeriod.views = 0
                this.totals.totalPeriod.clicks = 0
                this.totals.totalPeriod.uniqViews = 0
                this.totals.totalPeriod.shows = 0
                if (tempDataTotal.length > 0) {
                  tempDataTotal.forEach(item => {
                    this.totals.totalPeriod.views += item.views
                    this.totals.totalPeriod.clicks += item.clicks
                    this.totals.totalPeriod.shows += item.shows
                    this.totals.totalPeriod.uniqViews += item.uniqViews
                  })
                }
              })
            })
        })
    },
  },
}
</script>
